import React, { ChangeEvent, useEffect, useState } from "react";
import { createEnumDeclaration } from "typescript";
import ReactSlider from "react-slider";
import { Box, Slider } from "@mui/material";
import "./style.css";
import axios from "axios";
import { TIMEOUT } from "dns";
import { apiServer } from "../cinfig";

interface SortProps {
  configuration: ConfigurationDetail;
  setConfigurationSort: any;
  onConfigurationChange: (newConfig: ConfigurationDetail) => void;
  sort: String;
  price: Price;
}
interface ConfigurationDetail {
  filteredResult: Configuration[];
  maxValue: string;
  minValue: string;
}
interface Configuration {
  supllier: string;
  system1: string;
  price: number;
}
interface SystemInfo {
  supllier: string;
  system1: string;
  slogan: string;
  specialfeature: string;
  patentprotection: number;
}

interface Price {
  minPrice: string;
  maxPrice: string;
}
const Sort: React.FC<SortProps> = ({
  configuration,
  setConfigurationSort,
  onConfigurationChange,
  sort,
  price,
}) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const getDataFromSession = (key: string, defaultValue: any) => {
    const storedData = localStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : defaultValue;
  };
  const doors = getDataFromSession("doors", []);
  const keys = getDataFromSession("keys", []);
  const [tableData, setTableData] = useState<SystemInfo[]>([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState<string>("");
  const [openManufacturerList, setOpenManufacturerList] =
    useState<boolean>(false);

  const [value, setValue] = useState<number[]>([
    parseFloat(price.minPrice),
    parseFloat(price.maxPrice),
  ]);

  useEffect(() => {
    // const fetchData = async () => {
    //   try {
    //     const response = await axios.get(
    //       `${apiServer}/api/v1/configurator/systemsInfo`
    //     );
    //     setTableData(response.data);
    //   } catch (error) {
    //     console.error("Błąd podczas pobierania danych:", error);
    //   }
    //   try {
    //     const response = await axios.post(
    //       `${apiServer}/api/v1/configurator/configuration?supllierFilter=${selectedManufacturer}&minPrice=${value[0]}&maxPrice=${value[1]}`,
    //       [{ doors, keys }]
    //     );
    //     onConfigurationChange({
    //       ...configuration,
    //       filteredResult: response.data.filteredResult,
    //     });
    //   } catch (error) {
    //     console.error("Błąd podczas pobierania danych:", error);
    //   }
    // };
   
    if (!isInitialized && price.minPrice && price.maxPrice) {
      setValue([parseFloat(price.minPrice), parseFloat(price.maxPrice)]);
      setIsInitialized(true); // Oznacz wartości jako zainicjalizowane
    }
    const filterData = async () => {
      let data;
      if (selectedManufacturer !== "") {
        console.log(`Cena ${value}`)
        data = configuration.filteredResult.filter(
          (el) =>
            el.supllier === selectedManufacturer &&
            el.price >=
              (value[0] === 0 && value[1] === 0 ? el.price : value[0]) &&
            el.price <= (value[0] === 0 && value[1] === 0 ? el.price : value[1])
        );
      } else {
        data = configuration.filteredResult.filter(
          (el) =>
            el.price >=
              (value[0] === 0 && value[1] === 0 ? el.price : value[0]) &&
            el.price <= (value[0] === 0 && value[1] === 0 ? el.price : value[1])
        );
      }
      return data;
    };

    // fetchData();
    filterData().then((data) =>
      setConfigurationSort({
        filteredResult: data,
      })
    );
  }, [value, selectedManufacturer, sort,price.minPrice, price.maxPrice, isInitialized]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiServer}/api/v1/configurator/systemsInfo`
        );
        setTableData(response.data);
      } catch (error) {
        console.error("Błąd podczas pobierania danych:", error);
      }
    };
    fetchData();
    if (value[0] === 0 || value[1] === 0) {
      setValue([Number(price.minPrice), Number(price.maxPrice)]);
    }
  }, []);
  useEffect(() => {
    if (
      value[0] < Number(price.minPrice) ||
      value[1] > Number(price.maxPrice)
    ) {
      setValue([Number(price.minPrice), Number(price.maxPrice)]);
    }
  }, [sort]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const index = event.target.name === "min" ? 0 : 1;
    const newValue = event.target.value
      ? parseFloat(event.target.value)
      : value[index]; // Użyj bieżącej wartości zamiast resetować
    const clampedValue = Math.min(Math.max(newValue, 0), 1076);

    const updatedValue = [...value];
    updatedValue[index] = clampedValue;

    if (updatedValue[0] === Infinity || updatedValue[1] === Infinity) {
      updatedValue[0] = Number(price.minPrice);
      updatedValue[1] = Number(price.maxPrice);
    }
    if (!isNaN(newValue)) {
      // Ustaw nową wartość dla odpowiedniego indeksu, zachowując drugą wartość
      let updatedValue = [...value];
      updatedValue[index] = newValue;
      setValue(updatedValue);
    }
  };

  const buttonClass = {};

  const uniqueSuppliers = [...new Set(tableData.map((item) => item.supllier))];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          marginBottom: "10px",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div style={{ position: "relative", marginRight: "100px" }}>
          {/*<div
            className="SortButtons"
            onClick={() => setOpenManufacturerList(!openManufacturerList)}
            style={{ cursor: "pointer" }}
          >
            <div className="text-wrapper">Hersteller wählen</div>
            <img
              className="vector"
              style={{ rotate: openManufacturerList ? "0deg" : "90deg" }}
              alt="Vector"
              src="Vector.svg"
            />
      </div>*/}
          <div
            style={{
              left: 0,
              width: "100%",
              backgroundColor: "#fff",
              border: "1px solid #ccc",
              borderRadius: "4px",
              zIndex: 100,
            }}
          >
            <select
              value={selectedManufacturer}
              onChange={(e) => {
                setSelectedManufacturer(e.target.value);
                setOpenManufacturerList(false); // Zamyka listę po wybraniu wartości
              }}
              style={{
                width: "100%",
                padding: "8px",
                border: "none",
                outline: "none",
                borderRadius: "4px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              <option value="">Alle Hersteller</option>
              {uniqueSuppliers.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div style={{ marginRight: "20px" }}>
          <div>
            Preis von{" "}
            <input
              type="number"
              style={{ width: "60px", textAlign: "center" }}
              placeholder={price.minPrice}
              value={value[0]}
              name="min"
              onChange={handleChangeInput}
            />{" "}
            € bis{" "}
            <input
              type="number"
              style={{ width: "60px", textAlign: "center" }}
              placeholder={price.maxPrice}
              value={value[1]}
              name="max"
              onChange={handleChangeInput}
            />{" "}
            €
          </div>
        </div>
        <div style={{}}>
          <div>
            <Box sx={{ width: 150 }}>
              <Slider
                getAriaLabel={() => "Zakres cen"}
                value={[Number(value[0]), Number(value[1])]}
                min={Number(price.minPrice)}
                max={Number(price.maxPrice)}
                onChange={handleChange}
                valueLabelDisplay="auto"
              />
            </Box>
          </div>
        </div>
      </div>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        <div>
          <div className="SortButtons">
            <div className="text-wrapper">Hersteller A-Z</div>
            <img
              className="vector"
              style={{ rotate: "90deg" }}
              alt="Vector"
              src="Vector.svg"
            />
          </div>
        </div>
        <div style={{}}>
          <div className="SortButtons">
            <div className="text-wrapper">Hersteller Z-A</div>
            <img
              className="vector"
              style={{ rotate: "90deg" }}
              alt="Vector"
              src="Vector.svg"
            />
          </div>
        </div>
        <div style={{}}>
          <div className="SortButtons">
            <div className="text-wrapper">Niedrigster Preis</div>
            <img
              className="vector"
              style={{ rotate: "90deg" }}
              alt="Vector"
              src="Vector.svg"
            />
          </div>
        </div>
        <div style={{}}>
          <div className="SortButtons">
            <div className="text-wrapper">Höchster Preis</div>
            <img
              className="vector"
              style={{ rotate: "90deg" }}
              alt="Vector"
              src="Vector.svg"
            />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Sort;
