import React, { useState, useRef, ChangeEvent, useEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import LoadingPage from "../LoadingPage";

interface Door {
  id: number;
  doorName: string;
  lockAmount: number;
  lockType: string;
  lockOutside: number;
  lockInside: number;
}

interface Key {
  id: number;
  keyName: string;
  amount: number;
  opensDoor?: [number];
}

interface Permission {
  id: number;
  idDoor: number;
  idKey: number;
  allow: boolean;
}

const LockConfigurator = () => {
  const [pageIsLoaded, setPageIsLoaded] = useState<boolean>(false);
  const getDefaultLockValues = (lockType: string) => {
    switch (lockType) {
      case "N+G":
      case "DPZ":
      case "KPZ":
        return { lockOutside: 31, lockInside: 31 };
      case "HPZ":
        return { lockOutside: 31, lockInside: 0 };
      case "VHS":
      case "Briefkastenzylinder":
        return { lockOutside: 0, lockInside: 0 };
      default:
        return { lockOutside: 0, lockInside: 0 };
    }
  };

  const getStoredData = (key: string, defaultValue: any) => {
    const storedData = localStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : defaultValue;
  };

  const storeData = (key: string, data: any) => {
    localStorage.setItem(key, JSON.stringify(data));
  };

  const [doors, setDoors] = useState<Door[]>(
    getStoredData("doors", [
      {
        id: 1,
        doorName: "Tür 1",
        lockAmount: 1,
        lockType: "DPZ",
        lockOutside: 31,
        lockInside: 31,
      },
      {
        id: 2,
        doorName: "Tür 2",
        lockAmount: 1,
        lockType: "HPZ",
        lockOutside: 31,
        lockInside: 0,
      },
      {
        id: 3,
        doorName: "Tür 3",
        lockAmount: 1,
        lockType: "KPZ",
        lockOutside: 31,
        lockInside: 31,
      },
    ])
  );
  const [keys, setKeys] = useState<Key[]>(
    getStoredData("keys", [
      {
        id: 1,
        keyName: "Schlüssel 1",
        amount: 1,
      },
      {
        id: 2,
        keyName: "Schlüssel 2",
        amount: 1,
      },
      {
        id: 3,
        keyName: "Schlüssel 3",
        amount: 1,
      },
    ])
  );
  const [permission, setPermission] = useState<Permission[]>(
    getStoredData("permission", [
      { id: 1, idKey: 1, idDoor: 1, allow: true },
      { id: 2, idKey: 2, idDoor: 2, allow: true },
      { id: 3, idKey: 3, idDoor: 3, allow: true },
    ])
  );

  useEffect(() => {
    // Zapisuj dane do localStorage po każdej zmianie
    try {
      storeData("doors", doors);
      storeData("keys", keys);
      storeData("permission", permission);
    } finally {
      setPageIsLoaded(true);
    }
  }, [doors, keys, permission]);

  const getNextDoorId = () => {
    return doors.length > 0 ? Math.max(...doors.map((door) => door.id)) + 1 : 1;
  };

  const getNextKeyId = () => {
    return keys.length > 0 ? Math.max(...keys.map((key) => key.id)) + 1 : 1;
  };

  const getNextPermissionId = () => {
    return permission.length > 0
      ? Math.max(...permission.map((perm) => perm.id)) + 1
      : 1;
  };

  const handleInputChange = (
    index: number,
    field: keyof Door,
    value: string | number,
    numb: number,
    step: number,
    name: string,
    innen: number,
    auben: number
  ) => {
    let dozmiany = numb + step;
    if (dozmiany == 26) {
      dozmiany = 27;
    }
    if (dozmiany == 37 || dozmiany == 36) {
      dozmiany = 35;
    }
    if (dozmiany == 30) {
      if (name == "HPZ") {
        dozmiany = 31;
      } else {
        dozmiany = 31;
      }
    }
    if (dozmiany > 120) {
      return 0;
    }
    if (dozmiany < 30 && (auben == 27 || innen == 27)) {
      return 0;
    }
    if (dozmiany == 32) {
      dozmiany = 31;
    }

    if (Number(dozmiany) < 27) {
      console.log(dozmiany);
      return 0;
    } else if (Number(dozmiany) < 31 && name == "HPZ") {
      console.log(dozmiany);
      return 0;
    } else {
      const updatedDoors = [...doors];
      updatedDoors[index][field] = value as never;

      if (field === "lockOutside" || field === "lockInside") {
        // Handle increment or decrement by 5
        updatedDoors[index][field] =
          (parseInt(updatedDoors[index][field].toString(), 10) || 0) + dozmiany;
      }

      setDoors(updatedDoors);
    }
  };

  const clearLocalStorage = () => {
    localStorage.clear();
    // Odśwież stronę po wyczyszczeniu localStorage
    window.location.reload();
  };

  const handleAddDoor = () => {
    setDoors([
      ...doors,
      {
        id: getNextDoorId(),
        doorName: `Tür ${getNextDoorId()}`,
        lockAmount: 1,
        lockType: "N+G",
        lockOutside: 31,
        lockInside: 31,
      },
    ]);
  };

  const handleRemoveDoor = () => {
    if (doors.length > 1) {
      const updatedDoors = [...doors];
      updatedDoors.pop();
      setDoors(updatedDoors);
    } else {
      alert("Es ist nicht möglich, alle Elemente zu entfernen.");
    }
  };

  const handleAddKey = () => {
    setKeys([
      ...keys,
      {
        id: getNextKeyId(),
        keyName: `Schlüssel ${getNextKeyId()}`,
        amount: 1,
      },
    ]);
  };
  const handleRemoveKey = () => {
    if (keys.length > 1) {
      const updatedKeys = [...keys];
      updatedKeys.pop();
      setKeys(updatedKeys);
    } else {
      alert("Es ist nicht möglich, alle Elemente zu entfernen.");
    }
  };

  const handlePermissionChange = (
    idKey: number,
    idDoor: number,
    allow: boolean
  ) => {
    const existingPermissionIndex = permission.findIndex(
      (perm) => perm.idKey === idKey && perm.idDoor === idDoor
    );

    if (existingPermissionIndex !== -1) {
      // Permission already exists, remove it
      setPermission((prevPermission) => {
        const updatedPermission = [...prevPermission];
        updatedPermission.splice(existingPermissionIndex, 1);
        return updatedPermission;
      });
    } else {
      // Permission doesn't exist, add new permission
      setPermission((prevPermission) => [
        ...prevPermission,
        { id: getNextPermissionId(), idKey, idDoor, allow },
      ]);
    }
  };

  const handleDoorChange = (
    index: number,
    field: keyof Door,
    value: string | number
  ) => {
    const updatedDoors = [...doors];
    if (String(value) === "NaN") {
      value = 0;
    }
    if (field === "lockAmount" && Number(value) < 1) {
      alert("Die Menge darf nicht kleiner als 1 sein.");
      updatedDoors[index][field] = 1 as never;
    } else {
      updatedDoors[index][field] = value as never;
    }

    if (field === "lockType") {
      // Aktualizuj wartości domyślne po zmianie typu zamka
      updatedDoors[index] = {
        ...updatedDoors[index],
        ...getDefaultLockValues(value as string),
      };
    }

    setDoors(updatedDoors);
  };
  const handleKeyChange = (
    index: number,
    field: keyof Key,
    value: number | string
  ) => {
    const updatedKeys = [...keys];
    if (String(value) === "NaN") {
      value = 0;
    }
    if (field === "amount" && Number(value) < 1) {
      alert("Die Menge darf nicht kleiner als 1 sein.");
      updatedKeys[index][field] = 1 as never;
    } else {
      updatedKeys[index][field] = value as never;
    }
    setKeys(updatedKeys);
  };
  const validatePermissions = () => {
    // Sprawdź, czy istnieją dwa klucze o tych samych uprawnieniach
    interface Dane {
      [klucz: string]: number[];
    }
    function czySaTakieSameTablice(dane: Dane): boolean {
      const posortowaneDane: Dane = {};

      for (const klucz in dane) {
        const posortowanaTablica = dane[klucz].slice().sort();
        posortowaneDane[klucz] = posortowanaTablica;
      }

      for (const klucz1 in posortowaneDane) {
        for (const klucz2 in posortowaneDane) {
          if (
            klucz1 !== klucz2 &&
            posortowaneDane[klucz1].toString() ===
              posortowaneDane[klucz2].toString()
          ) {
            return true;
          }
        }
      }

      return false;
    }
    let keyWithoutPerm = false;

    function checkDuplicatePermissions(permissions: Permission[]): boolean {
      type UniqueKeyArrayObject = Record<number, number[]>;
      const myObject: UniqueKeyArrayObject = {};

      function addToKey(key: number, value: number): void {
        if (myObject[key]) {
          myObject[key].push(value);
        } else {
          myObject[key] = [value];
        }
      }
      permission.map((record) => {
        addToKey(record.idKey, record.idDoor);
      });
      // checkKeyWithoutPerm
      keys.forEach((key) => {
        if (myObject[key.id] === undefined) keyWithoutPerm = true;
        console.log(key.id, myObject[key.id], myObject);
      });

      //
      const wynik: boolean = czySaTakieSameTablice(myObject);

      return wynik;
    }

    const duplicatePermissions = checkDuplicatePermissions(permission);

    // Sprawdź, czy istnieją zamki bez przypisanego klucza
    const locksWithoutKey = doors.some(
      (door) => !permission.some((perm) => perm.idDoor === door.id)
    );

    return { duplicatePermissions, locksWithoutKey, keyWithoutPerm };
  };
  const handleNextPage = (e: React.MouseEvent) => {
    const { /* duplicatePermissions, */ locksWithoutKey, keyWithoutPerm } =
      validatePermissions();

    if (/*duplicatePermissions ||*/ locksWithoutKey || keyWithoutPerm) {
      e.preventDefault();
      // Wyświetl alert lub podejdź do walidacji w inny sposób
      alert(
        "Achtung: Zylinder oder Schlüssel ohne Schließberechtigung."
      );
    } else {
      // Przejdź do następnej strony
      // Tutaj możesz umieścić odpowiedni kod do obsługi przejścia do następnej strony
    }
  };
const updateIdsAfterRemoval = (items: any[], removedIndex: number): any[] => {
  return items.map((item) => {
    if (item.id > removedIndex) {
      return { ...item, id: item.id - 1 };
    }
    return item;
  });
};

const updatePermissionIds = (
  permission: any[],
  removedIndex: number,
  idField: string
): any[] => {
  return permission.map((permi) => {
    if (permi[idField] > removedIndex) {
      return { ...permi, [idField]: permi[idField] - 1 };
    }
    return permi;
  });
};

const handleRemoveItem = (
  index: number,
  items: any[],
  setItems: (items: any[]) => void,
  permission: any[],
  setPermission: (permission: any[]) => void,
  permissionIdField: string
): void => {
  if (items.length > 1) {
    let updatedItems = items.filter((item) => item.id !== index);
    updatedItems = updateIdsAfterRemoval(updatedItems, index);

    let updatedPermission = permission.filter(
      (permi) => permi[permissionIdField] !== index
    );
    updatedPermission = updatePermissionIds(
      updatedPermission,
      index,
      permissionIdField
    );

    setPermission(updatedPermission);
    setItems(updatedItems);
  } else {
    alert("Es ist nicht möglich, alle Elemente zu entfernen.");
  }
};

const handleRemoveKeyx = (index: number): void => {
  handleRemoveItem(index, keys, setKeys, permission, setPermission, "idKey");
};

const handleRemoveDoorx = (index: number): void => {
  handleRemoveItem(index, doors, setDoors, permission, setPermission, "idDoor");
};







  return (
    <>
      {pageIsLoaded ? (
        <div>
          <div
            className="configurator-container"
            style={{
              width: "75%",
              margin: "0 auto",
              overflow: "hidden",
              marginTop: "65px",
            }}
          >
            <div className="description">
              <div className="descriptionP">
              <h1>Unser Konfigurator macht es Ihnen leicht, Ihren individuellen Schließplan zu erstellen:</h1>
    <div className="step">
        <div className="step-title">1. Türbezeichnung:</div>
        <div className="step-description">
            Tragen Sie alle Bezeichnungen für Türen, Tore, Haupteingang, Briefkasten usw. ein, um die Zuordnung der Schließzylinder beim Einbau zu erleichtern. Geben Sie zusätzlich die notwendige Stückzahl der Zylinder an.
        </div>
    </div>
    <div className="step">
        <div className="step-title">2. Zylindertyp und Längen:</div>
        <div className="step-description">
            Geben Sie die Maße für "außen" und "innen" ein. Unser System akzeptiert Werte von 27 bis 120 mm. Beachten Sie, dass sich die Länge in Schritten von 5 mm ändern kann.
        </div>
    </div>
    <div className="step">
        <div className="step-title">3. Schlüssel:</div>
        <div className="step-description">
            Geben Sie die benötigte Schlüsselanzahl und den Namen der Schlüssel (General, Post, Reinigung, Gäste etc.) an.
        </div>
    </div>
    <div className="step">
        <div className="step-title">4. Schließplan-Matrix:</div>
        <div className="step-description">
            Geben Sie für jeden Schlüssel durch ein Kreuz die Schließberechtigung für den jeweiligen Zylinder an.
        </div>
    </div>
              </div>
              <div className="doorPanel">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    width: "30%",
                  }}
                >
                  <h1>Türbezeichnung</h1>
                  {/* <div className="buttonA" onClick={handleRemoveDoor}>
                    <img
                      src="/img/configurator/minus.svg"
                      alt="minus"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </div> */}

                  <div className="buttonB" onClick={handleAddDoor}>
                    <img
                      src="/img/configurator/plus.svg"
                      alt="minus"
                      style={{ width: "20px", height: "20px" ,  margin: "auto"}}
                    />
                  </div>
                </div>

                <p
                  style={{
                    width: "16%",
                    textAlign: "center",
                  }}
                >
                  Stück
                </p>
                <p
                  style={{
                    width: "24%",
                    textAlign: "center",
                  }}
                >
                  Zylindertyp
                </p>
                <div
                  style={{
                    width: "15%",
                    marginLeft: "11%",
                    textAlign: "center",
                  }}
                >
                  <p>Länge in mm</p>
                  <div>
                    <p>außen</p>
                    <p>innen</p>
                  </div>
                </div>
              </div>
              <div className="keyPanel">
                <div className="keyPanelContent">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      width: "50%",
                    }}
                  >
                    <h1>Schlüssel</h1>
                    {/* <div className="buttonA" onClick={handleRemoveKey}>
                      <img
                        src="/img/configurator/minus.svg"
                        alt="minus"
                        style={{ width: "20px", height: "20px" }}
                      />
                    </div> */}
                    <div className="buttonB" onClick={handleAddKey} >
                      <img
                        src="/img/configurator/plus.svg"
                        alt="minus"
                        style={{ width: "20px", height: "20px" }}
                      />
                    </div>
                  </div>
                  <p>Stück</p>
                </div>
              </div>
            </div>
            <div className="key-table">
              {keys.map((el, index) => (
                <div key={index} className="key-table-row">
                  <img
                    src="XRed.svg"
                    alt="X"
                    style={{
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                      padding: "4px",
                    }}
                    onClick={() => handleRemoveKeyx(Number(el.id))}
                  />
                  <input
                    type="number"
                    className="inputCenter"
                    placeholder=""
                    value={el.amount}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleKeyChange(
                        index,
                        "amount",
                        parseInt(e.target.value, 10)
                      )
                    }
                  />

                  <input
                    type="text"
                    value={el.keyName}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleKeyChange(index, "keyName", e.target.value)
                    }
                  />
                </div>
              ))}
            </div>
            <div className="door-table">
              {doors.map((el, index) => (
                <div key={index} className="door-table-row">
                  <img
                    src="XRed.svg"
                    alt="X"
                    style={{
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                      padding: "4px",
                      marginInline: "1%",
                    }}
                    onClick={() => handleRemoveDoorx(Number(el.id))}
                  />
                  <input
                    type="text"
                    placeholder="Name"
                    value={el.doorName}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleDoorChange(index, "doorName", e.target.value)
                    }
                  />
                  <input
                    type="number"
                    placeholder=""
                    className="inputCenter"
                    style={{
                      marginInline: "32px",
                    }}
                    value={el.lockAmount}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleDoorChange(
                        index,
                        "lockAmount",
                        parseInt(e.target.value, 10)
                      )
                    }
                  />
                  <div
                    style={{
                      display: "flex",
                      marginLeft: "7px",
                      width: "30%",
                    }}
                  >
                    <img src={`/img/${el.lockType}.png`} alt="picture"></img>
                    <select
                      style={{
                        width: "145px",
                        border: "none",
                        backgroundColor: "#F3F3F3",
                        borderBottom: "#B1B1C5 solid 1px",
                        marginLeft: "10px",
                      }}
                      value={el.lockType}
                      onChange={(e) =>
                        handleDoorChange(index, "lockType", e.target.value)
                      }
                    >
                      <option value="N+G">Doppelzylinder N+G</option>
                      <option value="DPZ">Doppelzylinder</option>
                      <option value="HPZ">Halbzylinder</option>
                      <option value="KPZ">Knaufzylinder</option>
                      <option value="VHS">Vorhängeschloss</option>
                      <option>Briefkastenzylinder</option>
                    </select>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "20%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        width: "45%",
                      }}
                    >
                      <div className="inputValueChangerForm">
                        <div
                          onClick={() =>
                            handleInputChange(
                              index,
                              "lockOutside",
                              "",
                              el.lockOutside,
                              -5,
                              el.lockType,
                              el.lockInside,
                              el.lockOutside
                            )
                          }
                        >
                          <img
                            src="/img/configurator/minus.svg"
                            alt="minus"
                            style={{ width: "12px" }}
                          />
                        </div>
                      </div>
                      <input
                        style={{
                          width: "30px",
                          border: "none",
                          backgroundColor: "#F3F3F3",
                          borderBottom: "#B1B1C5 solid 1px",
                        }}
                        type="number"
                        readOnly={true}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleDoorChange(
                            index,
                            "lockOutside",
                            parseInt(e.target.value, 10)
                          )
                        }
                        className="inputCenter"
                        value={
                          el.lockOutside === 0 ? "" : el.lockOutside.toString()
                        }
                        placeholder={
                          getDefaultLockValues(
                            el.lockType
                          ).lockOutside?.toString() || ""
                        }
                        disabled={
                          el.lockType === "VHS" ||
                          el.lockType === "Briefkastenzylinder"
                            ? true
                            : false
                        }
                      />
                      <div className="inputValueChangerForm">
                        <div
                          onClick={() =>
                            handleInputChange(
                              index,
                              "lockOutside",
                              "",
                              el.lockOutside,
                              5,
                              el.lockType,
                              el.lockInside,
                              el.lockOutside
                            )
                          }
                        >
                          <img
                            src="/img/configurator/plus.svg"
                            alt="minus"
                            style={{ width: "12px" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        width: "45%",
                      }}
                    >
                      <div className="inputValueChangerForm">
                      <div
                        onClick={() =>
                          handleInputChange(
                            index,
                            "lockInside",
                            "",
                            el.lockInside,
                            -5,
                            el.lockType,
                            el.lockInside,
                            el.lockOutside
                          )
                        }
                      >
                        <img
                          src="/img/configurator/minus.svg"
                          alt="minus"
                          style={{ width: "12px" }}
                        />
                      </div>
                      </div>
                      <input
                        style={{
                          width: "30px",
                          border: "none",
                          backgroundColor: "#F3F3F3",
                          borderBottom: "#B1B1C5 solid 1px",
                        }}
                        type="number"
                        readOnly={true}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleDoorChange(
                            index,
                            "lockInside",
                            parseInt(e.target.value, 10)
                          )
                        }
                        className="inputCenter"
                        value={
                          el.lockInside === 0 ? "" : el.lockInside.toString()
                        }
                        placeholder={
                          getDefaultLockValues(
                            el.lockType
                          ).lockInside?.toString() || ""
                        }
                        disabled={
                          el.lockType === "HPZ" ||
                          el.lockType === "VHS" ||
                          el.lockType === "Briefkastenzylinder"
                            ? true
                            : false
                        }
                      />
                      <div className="inputValueChangerForm">
                        <div
                          onClick={() =>
                            handleInputChange(
                              index,
                              "lockInside",
                              "",
                              el.lockInside,
                              5,
                              el.lockType,
                              el.lockInside,
                              el.lockOutside
                            )
                          }
                        >
                          <img
                            src="/img/configurator/plus.svg"
                            alt="minus"
                            style={{ width: "12px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "40px" }}></div>
                </div>
              ))}
            </div>
            <div className="permission-table">
              {doors.map((door, doorIndex) => (
                <div key={doorIndex} className="permission-table-row">
                  {keys.map((key, keyIndex) => (
                    <div
                      key={keyIndex}
                      onClick={() =>
                        handlePermissionChange(key.id, door.id, true)
                      }
                      className="permission-table-column"
                    >
                      {permission.some(
                        (perm) =>
                          perm.idKey === key.id && perm.idDoor === door.id
                      ) ? (
                        <img
                          src="X.svg"
                          alt="X"
                          style={{ width: "20px", height: "20px" }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div style={{ width: "1px", height: "80px" }}></div>

          <div
            className="LockConfiguratorButons"
            style={{ width: "70%", margin: "0 auto", overflow: "hidden" }}
          >
            <Link to={"/1"}>
              <span onClick={clearLocalStorage}>Alles zurücksetzen</span>
            </Link>
            <Link to="/2" onClick={handleNextPage}>
              <div className="LockConfiguratorButon">
                <div className="text-wrapper">Ergebnisse anzeigen</div>
                <img className="vector" alt="Vector" src="Vector.svg" />
              </div>
            </Link>
          </div>
        </div>
      ) : (
        <LoadingPage />
      )}
    </>
  );
};

export default LockConfigurator;
