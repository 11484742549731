import React, { ChangeEvent, useEffect, useState, useRef } from "react";
import "./OrderForm.css";
import axios from "axios";
import { randomBytes } from "crypto";
import SpecialOptions from "../3/SpecialOptions";
import { apiServer } from "../cinfig";
import { Link, Router } from "react-router-dom";

interface rechnungAdresse {
  vorname: string;
  nachname: string;
  kundenummer: string;
  email: string;
  streetAndHouse: string;
  zipCode: string;
  city: string;
  land: string;
  telephone: string;
  fax: string;
}

interface liferAdresse {
  vorname: string;
  nachname: string;
  streetAndHouse: string;
  zipCode: string;
  city: string;
  land: string;
}

interface email {
  id: string;
  rechnungAdresse: rechnungAdresse[];
  liferAdresse: liferAdresse[];
}
function isValidEmail(email: string): boolean {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return regex.test(email);
}
const OrderForm = () => {
  const getStoredData = (key: string, defaultValue: any) => {
    const storedData = localStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : defaultValue;
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [mongoID, setMongoID] = useState(localStorage.getItem("mongoID"));
  const [sendToOtherAddress, setSendToOtherAddress] = useState(false);
  const [buttonColor, setButtonColor] = useState("#005CA9");
  const [emailError, setEmailError] = useState(false);
  const [continueStep, setContinueStep] = useState(false);
  const [waitForSend, setWaitForSend] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [accept, setAccept] = useState(false);
  const [acceptAlert, setAcceptAlert] = useState(false);

  const [rechnungAdresses, setRechnungAdresse] = useState<rechnungAdresse[]>(
    getStoredData("rechnungAdresse", [
      {
        vorname: "",
        nachname: "",
        kundenummer: "",
        email: "",
        streetAndHouse: "",
        zipCode: "",
        city: "",
        land: "",
        telephone: "",
        fax: "",
      },
    ])
  );
  const [liferAdresse, setLiferAdresse] = useState<liferAdresse[]>(
    getStoredData("liferAdresse", [
      {
        vorname: "",
        nachname: "",
        streetAndHouse: "",
        zipCode: "",
        city: "",
        land: "",
      },
    ])
  );

  const storeData = (key: string, data: any) => {
    localStorage.setItem(key, JSON.stringify(data));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    // Zapisuj dane do localStorage po każdej zmianie
    storeData("rechnungAdresse", rechnungAdresses);
    storeData("liferAdresse", liferAdresse);
  }, [rechnungAdresses, liferAdresse]);

  const handleRechnungAdresseChange = (
    index: number,
    field: keyof rechnungAdresse,
    value: string
  ) => {
    const updatedRechnungAdresse = [...rechnungAdresses];
    if (field === "email") {
      if (!isValidEmail(value)) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
    }
    updatedRechnungAdresse[index][field] = value as never;
    setRechnungAdresse(updatedRechnungAdresse);
  };

  const handleLiferAdresse = (
    index: number,
    field: keyof liferAdresse,
    value: string
  ) => {
    const updatedLiferAdresse = [...liferAdresse];
    updatedLiferAdresse[index][field] = value as never;
    setLiferAdresse(updatedLiferAdresse);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const handleEmailSendOrder = () => {
    if (emailError) {
      alert("Bitte geben Sie eine gültige E-Mail-Adresse ein.");
      return;
    }
    const data: email = {
      id: String(localStorage.getItem("mongoID")),
      rechnungAdresse: rechnungAdresses,
      liferAdresse: liferAdresse,
    };
    setButtonColor("#116DBA");
    setTimeout(() => {
      setButtonColor("#005CA9");
    }, 1000);
    const send = async () => {
      setWaitForSend(true)
      try {
        const response = await axios.post(
          `${apiServer}/api/v1/configurator/email/order`,
          [data]
        );
        setSendEmail(true)
        // alert(
        //   response.data === "Wiadomości email wysłane"
        //     ? "E-Mail wurde gesendet"
        //     : response.data
        // );
        window.location.href =
          "https://www.wplusm.de/de/danke-fuer-schliessplanbestellung/";
      } catch (error) {
        console.error("Błąd podczas wysyłania email:", error);
      }
    };
    send();
  };
  const handleEmailSendContact = () => {
    if (emailError) {
      alert("Bitte geben Sie eine gültige E-Mail-Adresse ein.");
      return;
    }
    const data: email = {
      id: String(localStorage.getItem("mongoID")),
      rechnungAdresse: rechnungAdresses,
      liferAdresse: liferAdresse,
    };
    setButtonColor("#116DBA");
    setTimeout(() => {
      setButtonColor("#005CA9");
    }, 1000);
    const send = async () => {
      try {
        setWaitForSend(true)
        const response = await axios.post(
          `${apiServer}/api/v1/configurator/email/contact`,
          [data]
        );
        setSendEmail(false)
        // alert(
        //   response.data === "Wiadomości email wysłane"
        //     ? "E-Mail wurde gesendet"
        //     : response.data
        // );
        window.location.href =
          "https://www.wplusm.de/de/danke-fuer-anfrage-fuer-schliessanlage/";
      } catch (error) {
        console.error("Błąd podczas wysyłania email:", error);
      }
    };
    send();
  };

  const validForm = (): boolean => {
    let valid: boolean = true;
    if ((rechnungAdresses[0]?.vorname).length <= 0) valid = false;
    if ((rechnungAdresses[0]?.nachname).length <= 0) valid = false;
    // if ((rechnungAdresses[0]?.kundenummer).length <= 0) valid = false;
    if (
      (rechnungAdresses[0]?.email).length <= 0 &&
      !(rechnungAdresses[0]?.email).includes("@") &&
      !(rechnungAdresses[0]?.email).includes(".")
    )
      valid = false;
    if ((rechnungAdresses[0]?.streetAndHouse).length <= 0) valid = false;
    if ((rechnungAdresses[0]?.zipCode).length <= 3) valid = false;
    if ((rechnungAdresses[0]?.city).length <= 0) valid = false;

    if (sendToOtherAddress){
    if ((liferAdresse[0]?.vorname).length <= 0) valid = false;
    if ((liferAdresse[0]?.nachname).length <= 0) valid = false;
    if ((liferAdresse[0]?.streetAndHouse).length <= 0) valid = false;
    if ((liferAdresse[0]?.zipCode).length <= 3) valid = false;

    }
    
    return valid;
  };

  const handleValidForm = (): boolean => {
    let valid = validForm()
    if (valid) {
    } else {
      alert("Füllen Sie das Formular korrekt aus");
    }
    return valid;
  };
  return (
    <>
      <div
        style={{
          width: "75%",
          margin: "65px auto 0",
          border: "1px solid #ccc",
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginBottom: "20px",
            background: "#005CA9",
            color: "#FFFFFF",
            padding: "10px",
            marginLeft: "-20px",
            marginRight: "-20px",
            marginTop: "-20px",
            fontWeight: "bold",
          }}
        >
          <div style={{ flex: 1 }}>RECHNUGSADRESSE</div>
          <div>1/2</div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <form>
            {/* Etykiety i pola inputa */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "30%",
                }}
              >
                <label
                  style={{
                    color: "black",
                    marginBottom: "5px",
                    fontSize: "14px",
                  }}
                >
                  Vorname*
                </label>
                <input
                  type="text"
                  placeholder="Vorname"
                  style={{
                    height: "40px",
                    background: "#FFFFFF",
                    border: "1px solid #B1B1C5",
                    width: "100%",
                    fontSize: "14px",
                  }}
                  value={rechnungAdresses[0]?.vorname}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleRechnungAdresseChange(0, "vorname", e.target.value)
                  }
                  disabled={continueStep}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "30%",
                }}
              >
                <label
                  style={{
                    color: "black",
                    marginBottom: "5px",
                    fontSize: "14px",
                  }}
                >
                  Nachname*
                </label>
                <input
                  type="text"
                  placeholder="Nachname"
                  style={{
                    height: "40px",
                    background: "#FFFFFF",
                    border: "1px solid #B1B1C5",
                    width: "100%",
                    fontSize: "14px",
                  }}
                  value={rechnungAdresses[0]?.nachname}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleRechnungAdresseChange(0, "nachname", e.target.value)
                  }
                  disabled={continueStep}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "30%",
                }}
              >
                <label
                  style={{
                    color: "black",
                    marginBottom: "5px",
                    fontSize: "14px",
                  }}
                >
                  Kundenummer
                </label>
                <input
                  type="text"
                  placeholder="Kundenummer"
                  style={{
                    height: "40px",
                    background: "#FFFFFF",
                    border: "1px solid #B1B1C5",
                    width: "100%",
                    fontSize: "14px",
                  }}
                  value={rechnungAdresses[0]?.kundenummer}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleRechnungAdresseChange(
                      0,
                      "kundenummer",
                      e.target.value
                    )
                  }
                  disabled={continueStep}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                marginBottom: "10px",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "47%",
                }}
              >
                <label
                  style={{
                    color: "black",
                    marginBottom: "5px",
                    fontSize: "14px",
                  }}
                >
                  E-Mail-Adresse*
                </label>
                <input
                  type="email"
                  placeholder="E-Mail-Adresse"
                  style={{
                    height: "40px",
                    background: "#FFFFFF",
                    border: "1px solid #B1B1C5",
                    width: "100%",
                    fontSize: "14px",
                  }}
                  value={rechnungAdresses[0]?.email}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleRechnungAdresseChange(0, "email", e.target.value)
                  }
                  disabled={continueStep}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "47%",
                }}
              >
                <label
                  style={{
                    color: "black",
                    marginBottom: "5px",
                    fontSize: "14px",
                  }}
                >
                  Telefon
                </label>
                <input
                  type="text"
                  placeholder="Telefon"
                  style={{
                    height: "40px",
                    background: "#FFFFFF",
                    border: "1px solid #B1B1C5",
                    width: "100%",
                    fontSize: "14px",
                  }}
                  value={rechnungAdresses[0]?.telephone}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleRechnungAdresseChange(0, "telephone", e.target.value)
                  }
                  disabled={continueStep}
                />
              </div>
            </div>

            <div
              style={{ display: "flex", marginBottom: "10px", width: "100%" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <label
                  style={{
                    color: "black",
                    marginBottom: "5px",
                    fontSize: "14px",
                  }}
                >
                  Straße und Hausnummer*
                </label>
                <input
                  type="text"
                  placeholder="Straße und Hausnummer"
                  style={{
                    height: "40px",
                    background: "#FFFFFF",
                    border: "1px solid #B1B1C5",
                    width: "100%",
                    fontSize: "14px",
                  }}
                  value={rechnungAdresses[0]?.streetAndHouse}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleRechnungAdresseChange(
                      0,
                      "streetAndHouse",
                      e.target.value
                    )
                  }
                  disabled={continueStep}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "30%",
                }}
              >
                <label
                  style={{
                    color: "black",
                    marginBottom: "5px",
                    fontSize: "14px",
                  }}
                >
                  PLZ*
                </label>
                <input
                  type="text"
                  placeholder="PLZ"
                  style={{
                    height: "40px",
                    background: "#FFFFFF",
                    border: "1px solid #B1B1C5",
                    width: "100%",
                    fontSize: "14px",
                  }}
                  value={rechnungAdresses[0]?.zipCode}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleRechnungAdresseChange(0, "zipCode", e.target.value)
                  }
                  disabled={continueStep}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "30%",
                }}
              >
                <label
                  style={{
                    color: "black",
                    marginBottom: "5px",
                    fontSize: "14px",
                  }}
                >
                  Ort*
                </label>
                <input
                  type="text"
                  placeholder="Ort"
                  style={{
                    height: "40px",
                    background: "#FFFFFF",
                    border: "1px solid #B1B1C5",
                    width: "100%",
                    fontSize: "14px",
                  }}
                  value={rechnungAdresses[0]?.city}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleRechnungAdresseChange(0, "city", e.target.value)
                  }
                  disabled={continueStep}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "30%",
                }}
              >
                <label
                  style={{
                    color: "black",
                    marginBottom: "5px",
                    fontSize: "14px",
                  }}
                >
                  Land
                </label>
                <input
                  type="text"
                  placeholder="Land"
                  style={{
                    height: "40px",
                    background: "#FFFFFF",
                    border: "1px solid #B1B1C5",
                    width: "100%",
                    fontSize: "14px",
                  }}
                  value={rechnungAdresses[0]?.land}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleRechnungAdresseChange(0, "land", e.target.value)
                  }
                  disabled={continueStep}
                />
              </div>
            </div>
            <div
              style={{ display: "block", width: "100%", height: "100px" }}
            ></div>
          </form>
          {/* Pola wyboru */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "10px",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "45%",
              }}
            >
              <label
                style={{
                  color: "black",
                  marginBottom: "5px",
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="radio"
                  style={{ height: "15px", width: "15px", marginRight: "5px" }}
                  checked={!sendToOtherAddress}
                  onChange={() => setSendToOtherAddress(false)}
                />
                Lieferanschrift wie Rechnungsanschrift
              </label>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "45%",
              }}
            >
              <label
                style={{
                  color: "black",
                  marginBottom: "5px",
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="radio"
                  style={{ height: "15px", width: "15px", marginRight: "5px" }}
                  checked={sendToOtherAddress}
                  onChange={() => setSendToOtherAddress(true)}
                />
                Abweichende Lifernadrese
              </label>
            </div>
          </div>
        </div>

        <div
          className="flex-container"
          style={{ display: "flex", alignItems: "end" }}
        >
          {/* Napis * Pflichtfelder */}
          <div className="required-text">
            <p>* Pflichtfelder</p>
          </div>
        </div>
        <div
          className="flex-container"
          style={{
            display: "flex",
            alignItems: "end",
            width: "100%",
            margin: "0 auto",
          }}
        >
          {/* {!continueStep && (
            <div
              className="LockConfiguratorButon"
              style={{
                margin: "1% 0 0 0",
                cursor: "pointer",
                backgroundColor: buttonColor,
                transition: "background-color 0.5s ease",
              }}
              special-options
              onClick={(e) => {
                if (handleValidForm()) {
                  setContinueStep(true);
                  const navbarElement =
                    document.getElementById("special-options");
                  navbarElement?.scrollIntoView({ behavior: "smooth" });
                }
              }}
            >
              <div className="text-wrapper">Forsetzen</div>
              <img className="vector" alt="Vector" src="Vector.svg" />
            </div>
          )} */}
        </div>

        {/* Dodatkowy formularz dla adresu dostawy */}
        {sendToOtherAddress && (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                background: "#005CA9",
                color: "#FFFFFF",
                padding: "10px",
                fontWeight: "bold",
                marginRight: "-20px",
                marginLeft: "-20px",
                marginBottom: "20px",
              }}
            >
              <div style={{ flex: 1 }}>LIFERADRESSE</div>
              <div>2/2</div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <form>
                {/* Etykiety i pola inputa */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: "45%",
                    }}
                  >
                    <label
                      style={{
                        color: "black",
                        marginBottom: "5px",
                        fontSize: "14px",
                      }}
                    >
                      Vorname*
                    </label>
                    <input
                      type="text"
                      placeholder="Vorname"
                      style={{
                        height: "40px",
                        background: "#FFFFFF",
                        border: "1px solid #B1B1C5",
                        width: "100%",
                        fontSize: "14px",
                      }}
                      value={liferAdresse[0]?.vorname}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleLiferAdresse(0, "vorname", e.target.value)
                      }
                      disabled={continueStep}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: "45%",
                    }}
                  >
                    <label
                      style={{
                        color: "black",
                        marginBottom: "5px",
                        fontSize: "14px",
                      }}
                    >
                      Nachname*
                    </label>
                    <input
                      type="text"
                      placeholder="Nachname"
                      style={{
                        height: "40px",
                        background: "#FFFFFF",
                        border: "1px solid #B1B1C5",
                        width: "100%",
                        fontSize: "14px",
                      }}
                      value={liferAdresse[0]?.nachname}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleLiferAdresse(0, "nachname", e.target.value)
                      }
                      disabled={continueStep}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "10px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                  >
                    <label
                      style={{
                        color: "black",
                        marginBottom: "5px",
                        fontSize: "14px",
                      }}
                    >
                      Straße und Hausnummer*
                    </label>
                    <input
                      type="text"
                      placeholder="Straße und Hausnummer"
                      style={{
                        height: "40px",
                        background: "#FFFFFF",
                        border: "1px solid #B1B1C5",
                        width: "100%",
                        fontSize: "14px",
                      }}
                      value={liferAdresse[0]?.streetAndHouse}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleLiferAdresse(0, "streetAndHouse", e.target.value)
                      }
                      disabled={continueStep}
                    />
                  </div>
                </div>
                {emailError && (
                  <div style={{ color: "red" }}>
                    Bitte geben Sie eine gültige E-Mail-Adresse ein.
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: "30%",
                    }}
                  >
                    <label
                      style={{
                        color: "black",
                        marginBottom: "5px",
                        fontSize: "14px",
                      }}
                    >
                      PLZ*
                    </label>
                    <input
                      type="text"
                      placeholder="PLZ"
                      style={{
                        height: "40px",
                        background: "#FFFFFF",
                        border: "1px solid #B1B1C5",
                        width: "100%",
                        fontSize: "14px",
                      }}
                      value={liferAdresse[0]?.zipCode}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleLiferAdresse(0, "zipCode", e.target.value)
                      }
                      disabled={continueStep}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: "30%",
                    }}
                  >
                    <label
                      style={{
                        color: "black",
                        marginBottom: "5px",
                        fontSize: "14px",
                      }}
                    >
                      Ort*
                    </label>
                    <input
                      type="text"
                      placeholder="Ort"
                      style={{
                        height: "40px",
                        background: "#FFFFFF",
                        border: "1px solid #B1B1C5",
                        width: "100%",
                        fontSize: "14px",
                      }}
                      value={liferAdresse[0]?.city}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleLiferAdresse(0, "city", e.target.value)
                      }
                      disabled={continueStep}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: "30%",
                    }}
                  >
                    <label
                      style={{
                        color: "black",
                        marginBottom: "5px",
                        fontSize: "14px",
                      }}
                    >
                      Land
                    </label>
                    <input
                      type="text"
                      placeholder="Land"
                      style={{
                        height: "40px",
                        background: "#FFFFFF",
                        border: "1px solid #B1B1C5",
                        width: "100%",
                        fontSize: "14px",
                      }}
                      value={liferAdresse[0]?.land}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleLiferAdresse(0, "land", e.target.value)
                      }
                      disabled={continueStep}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <SpecialOptions disabled={true} />
      <div
        className="flex-container, LockConfiguratorButons"
        style={{
          display: "flex",
          alignItems: "flex-end",
          width: "75%",
          margin: "0 auto",
        }}
      >
        <label style={{ display: "flex", alignItems: "center", width: "35%", marginLeft: "auto"}}>
          <div
            style={{
              color: acceptAlert && !accept ? "red" : "#4a4a4a",
              display: "flex",
              alignItems: "center",
              fontSize: "0.4em",
            }}
          >
            <input
              style={{ margin: "1%", color: acceptAlert ? "red" : "#4a4a4a" }}
              type="checkbox"
              name="accept"
              id="accept"
              checked={accept}
              onChange={(e) => {
                setAccept(e.target.checked);
              }}
            />
            <div style={{ margin: "1%", fontSize: "2em", textAlign: "justify" }}>
              Ich habe die &nbsp;
              <a href="https://www.wplusm.de/de/agb/" target="_blank">
                Allgemeinen Geschäftsbedingungen
              </a>
              &nbsp; gelesen und akzeptiert, ebenfalls habe ich die{" "}
              <a
                href="https://www.wplusm.de/de/widerrufsbelehrung/"
                target="_blank"
              >
                Widerrufsbelehrung
              </a>
              &nbsp; sowie die Datenschutzbestimmung zur Kenntnis genommen.
            </div>
          </div>
        </label>
      </div>
      <div
        className="flex-container, LockConfiguratorButons"
        style={{
          display: "flex",
          alignItems: "end",
          width: "75%",
          margin: "0 auto",
        }}
      >
        <div style={{ placeSelf: "flex-start" }}>
          <div></div>
          <Link to={"/3"}>
            <div className="LockConfiguratorButon">
              <div className="text-wrapper1">Zurück</div>
            </div>
          </Link>
        </div>
        {!waitForSend && (
          <div>
            <div
              className="LockConfiguratorButon"
              style={{
                cursor: "pointer",
                backgroundColor: buttonColor,
                transition: "background-color 0.5s ease",
              }}
              onClick={(e) => {
                if (accept == true) {
                  if (handleValidForm()) {
                    handleEmailSendOrder();
                  }
                } else {
                  setAcceptAlert(true);
                }
              }}
            >
              <div className="text-wrapper">Kostenpflichtig bestellen</div>

              <img className="vector" alt="Vector" src="Vector.svg" />
            </div>
            <div
              className="LockConfiguratorButon"
              style={{
                backgroundColor: "white",
                border: "none",
                cursor: "pointer",
              }}
              onClick={(e) => {
                if (accept) {
                  if (handleValidForm()) {
                    handleEmailSendContact();
                  }
                } else {
                  setAcceptAlert(true);
                }
              }}
            >
              <div className="text-wrapper" style={{ color: "#005CA9" }}>
                Anfrage versenden
              </div>
              <img
                className="vector"
                style={{ width: "20.5px" }}
                alt="Vector"
                src="mail.svg"
              />
            </div>
          </div>
        )}

        {waitForSend && !sendEmail && (
          <div className={true ? "loader" : ""}></div>
        )}
        {waitForSend && sendEmail && (
          <img style={{ width: "50px" }} alt="Email" src="./img/email.png" />
        )}
      </div>
    </>
  );
};

export default OrderForm;
