import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const linkStyle = { color: '#005CA9', marginRight: '1rem' };

  return (
    <footer className="footer" style={{ backgroundColor: '#f5f5f5', padding: '1rem', fontSize: "14px", width: "100%", position: "fixed", bottom: 0 }}>
      <div className="content">
        <div className="columns is-multiline is-flex is-justify-content-flex-start">
          <div className="column is-full">
            <nav>
              <Link to="https://www.wplusm.de/Imprint" target="_blank" style={linkStyle}>
                Impressum
              </Link>
              <Link to="https://www.wplusm.de/PrivacyDisclaimer" target="_blank" style={linkStyle}>
                Datenschutz
              </Link>
              <Link to="https://wplusm.de/de/kontakt/" target="_blank" style={linkStyle}>
                Kontakt
              </Link>
              <Link to="https://www.wplusm.de/AGB" target="_blank" style={linkStyle}>
                AGB
              </Link>
              <Link to="https://www.wplusm.de/de/widerrufsbelehrung/" target="_blank" style={linkStyle}>
                Widerrufsrecht
              </Link>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
