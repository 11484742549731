import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import Nav from "./Nav";
import Footer from "./Footer";
import SpecialOptions from "./3/SpecialOptions";
import OrderForm from "./4/OrderForm";
import ChooseLockSystem from "./2/ChooseLockSystem";
import LockConfigurator from "./1/LockConfigurator";
import Home from "./Home";

const useRoutes = () => {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="1" element={<LockConfigurator />} />
      <Route path="2" element={<ChooseLockSystem />} />
      <Route path="3" element={<SpecialOptions disabled={false} />} />
      <Route path="4" element={<OrderForm />} />
    </Routes>
  );
};

function App() {
  return (
    <Router>
      <>
      <div className="App">
        <Nav />
        {useRoutes()}
        <div style={{width: "1px",height: "80px"}}></div>
        <Footer />
      </div>
    </>
      
    </Router>
  );
}

export default App;
