import React, { useEffect, useState } from "react";
import "bulma/css/bulma.min.css";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<number>(1);

  const handleTabClick = (tabNumber: number) => {
    setActiveTab(tabNumber);
  };

  return (
    <nav id="navbar" className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
      <div className="navbar-menu">
        <div className="navbar-start" ></div>

        <div className="navbar-start" style={{ width: "75%" }}>
          <div className="navbar-brand">
            <a href="/">
              <img src="W+Mlogo.svg" style={{ height: '3rem', padding: '3px' }} alt="Logo" />
            </a>
          </div>
          <div className="tabs is-boxed" style={{ width: "70%" }}>
            <ul className="is-justify-content-space-around">
              <li className={location.pathname === "/1" ? "is-active" : ""}>
                <Link to="/1">
                <div
                  onClick={() => handleTabClick(1)}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: location.pathname === "/1" ? "#005CA9" : "#B9B9BA",
                  }}
                >
                  <div style={{ justifyContent: "center" }}>
                    <img
                      src={
                        location.pathname === "/1"
                          ? "./img/1.png"
                          : "./img/1un.png"
                      }
                      alt="number"
                      style={{
                        width: "14px",
                        marginRight: "3px",
                      }}
                    ></img>
                    <span style={{ fontSize: "14px" }}>
                      Schließplan erstellen
                    </span>
                  </div>
                </div>
                </Link>
              </li>
              <li className={location.pathname === "/2" ? "is-active" : ""}>
                <Link to="/2">
                <div
                  onClick={() => handleTabClick(2)}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: location.pathname === "/2" ? "#005CA9" : "#B9B9BA",
                  }}
                >
                  <div>
                    <img
                      src={
                        location.pathname === "/2"
                          ? "./img/2.png"
                          : "./img/2un.png"
                      }
                      alt="number"
                      style={{
                        width: "14px",
                        marginRight: "3px",
                      }}
                    ></img>
                    <span style={{ fontSize: "14px" }}>Systemauswahl</span>
                  </div>
                </div>
                </Link>
              </li>
              <li className={location.pathname === "/3" ? "is-active" : ""}>
                <Link to="/3">
                <div
                  onClick={() => handleTabClick(3)}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: location.pathname === "/3" ? "#005CA9" : "#B9B9BA",
                  }}
                >
                  <div>
                    <img
                      src={
                        location.pathname === "/3"
                          ? "./img/3.png"
                          : "./img/3un.png"
                      }
                      alt="number"
                      style={{
                        width: "14px",
                        marginRight: "3px",
                      }}
                    ></img>
                    <span style={{ fontSize: "14px" }}>Zusammenfassung</span>
                  </div>
                </div>
                </Link>
              </li>
              <li className={location.pathname === "/4" ? "is-active" : ""}>
                <Link to="/4">
                <div
                  onClick={() => handleTabClick(4)}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: location.pathname === "/4" ? "#005CA9" : "#B9B9BA",
                  }}
                >
                  
                  <div>
                    <img
                      src={
                        location.pathname === "/4"
                          ? "./img/4.png"
                          : "./img/4un.png"
                      }
                      alt="number"
                      style={{
                        width: "14px",
                        marginRight: "3px",
                      }}
                    ></img>
                    <span style={{ fontSize: "14px" }}>
                      Bestellung
                    </span>
                  </div>
                </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
