import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

const HomePage = () => {
  // Efekt będzie wywoływany raz, gdy komponent zostanie zamontowany
  useEffect(() => {
    // Wyczyść local storage
    localStorage.clear();
  }, []); // Pusta tablica zależności oznacza, że efekt będzie wywoływany tylko raz po zamontowaniu komponentu

  return <Navigate to="/1" replace/>;
};

export default HomePage;
