import React, { ChangeEvent, useEffect, useState } from "react";
import logo3 from "./3.png";
import axios from "axios";
import { log } from "console";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { apiServer } from "../cinfig";
import LoadingPage from "../LoadingPage";

interface Door {
  doorName: string;
  priceBrutto: number;
  price: number;
  id: number;
  lockAmount: number;
  lockType: string;
  lockOutside: number;
  lockInside: number;
}

interface Key {
  keyName: string;
  price: number;
  priceBrutto: number;
  id: number;
  amount: number;
  opensDoor?: [number];
}

interface ConfigurationDetailed {
  supllier: string;
  model: string;
  doors: Door[];
  keys: Key[];
  sumBrutto: number;
  sumNetto: number;
  tax: number
}

interface Mongo {
  supllier: string;
  model: string;
  doors: Door[];
  keys: Key[];
  opensDoor: [];
}

interface Permission {
  id: number;
  idDoor: number;
  idKey: number;
  allow: boolean;
}

interface Props {
  disabled: boolean;
}

const SpecialOptions = (props: Props) => {
  const location = useLocation()
  const navigate = useNavigate();
    const getStoredData = (key: string, defaultValue: any) => {
      const storedData = localStorage.getItem(key);
      return storedData ? JSON.parse(storedData) : defaultValue;
    };
  const [pageIsLoaded, setPageIsLoaded] = useState<boolean>(false)
  const [configurationDetailed, setConfigurationDetailed] =
    useState<ConfigurationDetailed>();
  const getDataFromSession = (key: string, defaultValue: any) => {
    const storedData = localStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : defaultValue;
  };
  const storeData = (key: string, data: any) => {
    localStorage.setItem(key, JSON.stringify(data));
  };
    const [permission, setPermission] = useState<Permission[]>(
      getStoredData("permission", [
        { id: 1, idKey: 1, idDoor: 1, allow: true },
        { id: 2, idKey: 2, idDoor: 2, allow: true },
        { id: 3, idKey: 3, idDoor: 3, allow: true },
      ])
    );

  const saveToDB = () => {
    const permission = getDataFromSession("permission", []);

    const data: Mongo = {
      supllier: supllier,
      model: model,
      doors: doors,
      keys: keys,
      opensDoor: permission,
    };
    const save = async () => {
      try {
        const response = await axios.post(
          `${apiServer}/api/v1/configurator/mongo`,
          [data]
        );
        localStorage.setItem("mongoID", response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Błąd podczas zapisu danych:", error);
      }
    };
    save();
  };

  const [doors, setDoors] = useState<Door[]>(
    getDataFromSession("doors", [
      {
        id: 1,
        doorName: "Tür 1",
        lockAmount: 1,
        lockType: "N+G",
        lockOutside: 31,
        lockInside: 31,
      },
    ])
  );
  const [keys, setKeys] = useState<Key[]>(
    getDataFromSession("keys", [
      {
        id: 1,
        keyName: "Schlüssel 1",
        amount: 1,
      },
    ])
  );
  const permissionData = getDataFromSession("permission", []);
  const supllier = getDataFromSession("supllier", []);
  const model = getDataFromSession("system1", []);
  const [comment, setComment] = useState<string>(getDataFromSession("comment", ""))


  useEffect(() => {
    storeData("doors", doors);
    storeData("keys", keys);
    storeData("permission", permission);
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${apiServer}/api/v1/configurator/configurationDetailed`,
          [{ supllier, model, doors, keys, comment }]
        );
        if (response.data.model.length === 0) navigate("/2");
        setConfigurationDetailed(response.data);
      } catch (error) {
        console.error("Błąd podczas pobierania danych:", error);
      } finally {
        setPageIsLoaded(true);
      }
      // try {
      //   const response = await axios.get(
      //     `${apiServer}/api/v1/configurator/systemsInfo`
      //   );
      //   setConfigurationDetailed(response.data);
      // } catch (error) {
      //   console.error("Błąd podczas pobierania danych:", error);
      // }
    };
    fetchData();
  }, [doors, keys, permission]);

  const downloadExcel = () => {
    const save = async () => {
      const permission = getDataFromSession("permission", []);

      const data: Mongo = {
        supllier: supllier,
        model: model,
        doors: doors,
        keys: keys,
        opensDoor: permission,
      };
      try {
        const response = await axios.post(
          `${apiServer}/api/v1/configurator/mongo`,
          [data]
        );
        localStorage.setItem("mongoID", response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Błąd podczas zapisu danych:", error);
      }
      try {
        const response = await axios.get(
          `${apiServer}/api/v1/configurator/excel/${localStorage.getItem(
            "mongoID"
          )}`,
          { responseType: "blob" } // Ustawienie typu odpowiedzi na blob
        );

        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Zapisz plik przy użyciu FileSaver.js
        saveAs(blob, "Schliessplan.xlsx");

        console.log("Pobrano i zapisano plik xlsx.");
      } catch (error) {
        console.error("Błąd podczas pobierania danych:", error);
      }
    };
    save();
  };
  const findDoor = (doorName: string): Door | undefined => {
    const config = doors.find((config) => config.doorName === doorName);
    return config ? config : undefined;
  };
  const findKey = (keyName: string): Key | undefined => {
    const config = keys.find((config) => config.keyName === keyName);
    return config ? config : undefined;
  };

  const handleDoorChange = (
    index: number,
    field: keyof Door,
    value: string | number
  ) => {
    if (String(value) === "NaN") {
      value = 0;
    }
    const updatedDoors = [...doors];
    updatedDoors[index][field] = value as never;

    setDoors(updatedDoors);
  };
  const handleKeyChange = (
    index: number,
    field: keyof Key,
    value: number | string
  ) => {
    if (String(value) === "NaN") {
      value = 0;
    }
    const updatedKeys = [...keys];
    updatedKeys[index][field] = value as never;
    setKeys(updatedKeys);
  };
  const handleInputChangeAmont = (
    index: number,
    value: number,
    field: string,
    step: number
  ) => {
    if(field === "lockAmount"){
      if ((step >= 0 && value >=0) || (value > 0 && step <= 0)) {
      const updatedDoors = [...doors];
      updatedDoors[index].lockAmount = value + step
      setDoors(updatedDoors)
      }
    }
    if(field === "amount"){
      if ((step >= 0 && value >= 0) || (value > 0 && step <= 0)) {
        const updatedKeys = [...keys];
        updatedKeys[index].amount = value + step;
        setKeys(updatedKeys);
      }
    }
  }
  const handleInputChange = (
    index: number,
    field: keyof Door,
    value: string | number,
    numb: number,
    step: number,
    name: string,
    innen: number,
    auben: number
  ) => {
    let dozmiany = numb + step;
    if (dozmiany == 26) {
      dozmiany = 27;
    }
    if (dozmiany == 37 || dozmiany == 36) {
      dozmiany = 35;
    }
    if (dozmiany == 30) {
      if (name == "HPZ") {
        dozmiany = 32;
      } else {
        dozmiany = 31;
      }
    }
    if (dozmiany > 120) {
      return 0;
    }
    if (dozmiany < 30 && (auben == 27 || innen == 27)) {
      return 0;
    }
    if (name != "HPZ" && dozmiany == 32) {
      dozmiany = 31;
    }

    if (Number(dozmiany) < 27) {
      console.log(dozmiany);
      return 0;
    } else {
      const updatedDoors = [...doors];
      updatedDoors[index][field] = value as never;

      if (field === "lockOutside" || field === "lockInside") {
        // Handle increment or decrement by 5
        updatedDoors[index][field] =
          (parseInt(updatedDoors[index][field].toString(), 10) || 0) + dozmiany;
      }

      setDoors(updatedDoors);
    }
  };
const updateIdsAfterRemoval = (items: any[], removedIndex: number): any[] => {
  return items.map((item) => {
    if (item.id > removedIndex) {
      return { ...item, id: item.id - 1 };
    }
    return item;
  });
};

const updatePermissionIds = (
  permission: any[],
  removedIndex: number,
  idField: string
): any[] => {
  return permission.map((permi) => {
    if (permi[idField] > removedIndex) {
      return { ...permi, [idField]: permi[idField] - 1 };
    }
    return permi;
  });
};

const handleRemoveItem = (
  index: number,
  items: any[],
  setItems: (items: any[]) => void,
  permission: any[],
  setPermission: (permission: any[]) => void,
  permissionIdField: string
): void => {
  if (items.length > 1) {
    let updatedItems = items.filter((item) => item.id !== index);
    updatedItems = updateIdsAfterRemoval(updatedItems, index);

    let updatedPermission = permission.filter(
      (permi) => permi[permissionIdField] !== index
    );
    updatedPermission = updatePermissionIds(
      updatedPermission,
      index,
      permissionIdField
    );

    setPermission(updatedPermission);
    setItems(updatedItems);
  } else {
    alert("Es ist nicht möglich, alle Elemente zu entfernen.");
  }
};

const handleRemoveKeyx = (index: number): void => {
  handleRemoveItem(index, keys, setKeys, permission, setPermission, "idKey");
};

const handleRemoveDoorx = (index: number): void => {
  handleRemoveItem(index, doors, setDoors, permission, setPermission, "idDoor");
};

  return (
    <div
      id="special-options"
      className="special-options"
      style={{ width: "75%", margin: "65px auto" }}
    >
      {/* Tytuł */}
      <div
        style={{
          textAlign: "left",
          margin: "20px 0",
          padding: "1rem",
          display: props.disabled ? "none" : "block",
        }}
      >
        <p
          style={{
            fontSize: "1.2rem",
            fontWeight: 800,
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={logo3}
            alt="Tytuł"
            style={{ width: "1.2rem", marginRight: "0.3rem" }}
          />
          Zusammenfassung
        </p>
        <p style={{ fontSize: "0.6rem" }}>
          Für die gewählte Schließanlage {configurationDetailed?.supllier}{" "}
          {configurationDetailed?.model}
        </p>
      </div>
      <hr style={{ color: "" }} />
      {/* Tabela 1 */}
      {pageIsLoaded ? (
        <div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              padding: "20px",
            }}
          >
            <div
              style={{
                width: "100%",
                color: "black",
                display: "flex",
                alignItems: "center",
              }}
              className="is-size-6 has-text-weight-bold"
            >
              <img
                style={{
                  width: "70px",
                  height: "40px",
                  float: "left",
                  marginRight: "10px",
                }}
                src={`/img/${configurationDetailed?.supllier.trim()}.jpeg`}
                alt="logo"
              ></img>
              <div>
                {configurationDetailed?.supllier} {configurationDetailed?.model}
              </div>
              <div style={{ textAlign: "right", fontSize: "0.85rem", marginLeft: "auto" }}>
    <p style={{ margin: 0 }}>
      <strong>Hinweis: </strong>
      Registrierte Profikunden erhalten bei Bestellung in der separaten
      Auftragsbestätigung ihre individuellen Nettopreise.
    </p>
  </div>
            </div>
          </div>
          <div style={{ border: "1px solid #CBCBCB" }}>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  backgroundColor: "#005CA9",
                  textAlign: "center",
                  padding: "10px 2% 10px 2%",
                  display: "flex",
                  justifyContent: "space-between",
                  color: "#FFFFFF",
                  fontWeight: "bold",
                }}
              >
                <div className="is-size-6">Zylinder konfigurieren</div>
                <div className="is-size-6 has-text-weight-bold">
                  {configurationDetailed?.supllier}{" "}
                  {configurationDetailed?.model}
                </div>
              </div>
              <div
                style={{
                  textAlign: "start",
                  padding: "1%",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    padding: "4px",
                    marginLeft: "1%",
                  }}
                ></div>
                <div
                  style={{ width: "10%", color: "black", paddingLeft: "10px" }}
                  className="is-size-6 has-text-weight-bold"
                >
                  Bezeichnung
                </div>
                <div
                  style={{
                    width: "10%",
                    color: "black",
                    textAlign: "center",
                    paddingLeft: "3px",
                  }}
                  className="is-size-6 has-text-weight-bold"
                >
                  Anzahl
                </div>
                <div
                  style={{
                    width: "40%",
                    color: "black",
                    paddingLeft: "42px",
                  }}
                  className="is-size-6 has-text-weight-bold"
                >
                  Typ
                </div>
                <div
                  style={{
                    width: "20%",
                    color: "black",
                    paddingRight: "85px",
                    textAlign: "right",
                  }}
                  className="is-size-6 has-text-weight-bold"
                >
                  Netto
                </div>
                <div
                  style={{
                    width: "20%",
                    color: "black",
                    paddingRight: "112px",
                    textAlign: "right",
                  }}
                  className="is-size-6 has-text-weight-bold"
                >
                  Brutto
                </div>
              </div>
              {configurationDetailed?.doors.map((door, index) => (
                <div
                  key={door.doorName}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "1px solid #ddd",
                    backgroundColor: "#F3F3F3",
                    margin: "0.5% 1% 0.5% 1%",
                    alignItems: "center",
                  }}
                >
                  {props.disabled || (
                    <img
                      src="XRed.svg"
                      alt="X"
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                        padding: "4px",
                        marginLeft: "1%",
                      }}
                      onClick={() =>
                        handleRemoveDoorx(Number(findDoor(door.doorName)?.id))
                      }
                    />
                  )}
                  <div
                    style={{ width: "10%", color: "black", padding: "10px" }}
                  >
                    {door.doorName}
                  </div>
                  <div
                    style={{
                      width: "10%",
                      color: "black",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      {props.disabled || (
                        <div className="inputValueChangerForm">
                          <div
                            onClick={() =>
                              handleInputChangeAmont(
                                index,
                                Number(findDoor(door.doorName)?.lockAmount),
                                "lockAmount",
                                -1
                              )
                            }
                          >
                            <img
                              src="/img/configurator/minus.svg"
                              alt="minus"
                              style={{ width: "12px" }}
                            />
                          </div>
                        </div>
                      )}
                      <input
                        type="number"
                        readOnly={true}
                        disabled={props.disabled}
                        value={findDoor(door.doorName)?.lockAmount}
                        style={{
                          width: "35%",
                          border: "none",
                          backgroundColor: "#F3F3F3",
                          borderBottom: "#B1B1C5 solid 1px",
                          textAlign: "center",
                        }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleDoorChange(
                            index,
                            "lockAmount",
                            parseInt(e.target.value, 10)
                          )
                        }
                      />
                      {props.disabled || (
                        <div className="inputValueChangerForm">
                          <div
                            onClick={() =>
                              handleInputChangeAmont(
                                index,
                                Number(findDoor(door.doorName)?.lockAmount),
                                "lockAmount",
                                1
                              )
                            }
                          >
                            <img
                              src="/img/configurator/plus.svg"
                              alt="minus"
                              style={{ width: "12px" }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    style={{
                      width: "40%",
                      color: "black",
                      padding: "10px",
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    <img
                      src={`/img/${findDoor(door.doorName)?.lockType}.png`}
                      alt=""
                    ></img>
                    <div style={{ width: "10%", marginLeft: "5px" }}>
                      {findDoor(door.doorName)?.lockType ===
                      "Briefkastenzylinder"
                        ? "BKZ"
                        : findDoor(door.doorName)?.lockType}
                    </div>
                    innen:
                    {props.disabled || (
                      <div
                        className="inputValueChangerForm"
                        style={{ marginInlineStart: "5px", textAlign: "right" }}
                      >
                        <div
                          onClick={() =>
                            handleInputChange(
                              index,
                              "lockInside",
                              "",
                              Number(findDoor(door.doorName)?.lockInside),
                              -5,
                              String(findDoor(door.doorName)?.lockType),
                              Number(findDoor(door.doorName)?.lockInside),
                              Number(findDoor(door.doorName)?.lockOutside)
                            )
                          }
                        >
                          <img
                            src="/img/configurator/minus.svg"
                            alt="minus"
                            style={{ width: "12px" }}
                          />
                        </div>
                      </div>
                    )}
                    <input
                      type="number"
                      readOnly={true}
                      value={findDoor(door.doorName)?.lockInside}
                      style={{
                        width: "10%",
                        border: "none",
                        backgroundColor: "#F3F3F3",
                        borderBottom: "#B1B1C5 solid 1px",
                        textAlign: "center",
                        marginInline: "5px",
                      }}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleDoorChange(
                          index,
                          "lockInside",
                          parseInt(e.target.value, 10)
                        )
                      }
                      disabled={
                        props.disabled ||
                        findDoor(door.doorName)?.lockType === "VHS" ||
                        findDoor(door.doorName)?.lockType === "HPZ" ||
                        findDoor(door.doorName)?.lockType ===
                          "Briefkastenzylinder"
                          ? true
                          : false
                      }
                    />
                    {props.disabled || (
                      <div
                        className="inputValueChangerForm"
                        style={{ marginInlineEnd: "5px" }}
                      >
                        <div
                          onClick={() =>
                            handleInputChange(
                              index,
                              "lockInside",
                              "",
                              Number(findDoor(door.doorName)?.lockInside),
                              5,
                              String(findDoor(door.doorName)?.lockType),
                              Number(findDoor(door.doorName)?.lockInside),
                              Number(findDoor(door.doorName)?.lockOutside)
                            )
                          }
                        >
                          <img
                            src="/img/configurator/plus.svg"
                            alt="minus"
                            style={{ width: "12px" }}
                          />
                        </div>
                      </div>
                    )}
                    außen: 
                    {props.disabled || (
                      <div
                        className="inputValueChangerForm"
                        style={{ marginInlineStart: "5px", textAlign: "right" }}
                      >
                        <div
                          onClick={() =>
                            handleInputChange(
                              index,
                              "lockOutside",
                              "",
                              Number(findDoor(door.doorName)?.lockOutside),
                              -5,
                              String(findDoor(door.doorName)?.lockType),
                              Number(findDoor(door.doorName)?.lockInside),
                              Number(findDoor(door.doorName)?.lockOutside)
                            )
                          }
                        >
                          <img
                            src="/img/configurator/minus.svg"
                            alt="minus"
                            style={{ width: "12px" }}
                          />
                        </div>
                      </div>
                    )}
                    <input
                      type="number"
                      readOnly={true}
                      value={findDoor(door.doorName)?.lockOutside}
                      style={{
                        width: "10%",
                        border: "none",
                        backgroundColor: "#F3F3F3",
                        borderBottom: "#B1B1C5 solid 1px",
                        textAlign: "center",
                        marginInline: "5px",
                      }}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleDoorChange(
                          index,
                          "lockOutside",
                          parseInt(e.target.value, 10)
                        )
                      }
                      disabled={
                        props.disabled ||
                        findDoor(door.doorName)?.lockType === "VHS" ||
                        findDoor(door.doorName)?.lockType ===
                          "Briefkastenzylinder"
                          ? true
                          : false
                      }
                    />
                    {props.disabled || (
                      <div className="inputValueChangerForm">
                        <div
                          onClick={() =>
                            handleInputChange(
                              index,
                              "lockOutside",
                              "",
                              Number(findDoor(door.doorName)?.lockOutside),
                              5,
                              String(findDoor(door.doorName)?.lockType),
                              Number(findDoor(door.doorName)?.lockInside),
                              Number(findDoor(door.doorName)?.lockOutside)
                            )
                          }
                        >
                          <img
                            src="/img/configurator/plus.svg"
                            alt="minus"
                            style={{ width: "12px" }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      width: "20%",
                      fontSize: "14px",
                      color: "black",
                      padding: "10px",
                      textAlign: "right",
                    }}
                  >
                    {String(
                      door.price === null ? "0,0" : door.price.toFixed(2)
                    ).replace(".", ",")}{" "}
                    € ohne MwSt.
                  </div>
                  <div
                    style={{
                      width: "20%",
                      fontSize: "14px",
                      color: "black",
                      padding: "10px",
                      textAlign: "right",
                    }}
                  >
                    {String(
                      door.priceBrutto === null
                        ? "0,0"
                        : door.priceBrutto.toFixed(2)
                    ).replace(".", ",")}{" "}
                    € inkl. MwSt. 19%
                  </div>
                </div>
              ))}
            </div>

            {/* Tabela 2 */}
            <div style={{ width: "100%" }}>
              <div
                style={{
                  backgroundColor: "#005CA9",
                  textAlign: "center",
                  padding: "10px 2% 10px 2%",
                  display: "flex",
                  justifyContent: "space-between",
                  color: "#FFFFFF",
                  fontWeight: "bold",
                }}
              >
                <div className="is-size-6">Schlüssel konfigurieren</div>
                <div className="is-size-6 has-text-weight-bold">
                  {configurationDetailed?.supllier}{" "}
                  {configurationDetailed?.model}
                </div>
              </div>
              <div
                style={{
                  textAlign: "center",
                  padding: "10px",
                  display: "flex",
                }}
              >
                <div
                  style={{ width: "10%", color: "black" }}
                  className="is-size-6 has-text-weight-bold"
                ></div>
                <div
                  style={{ width: "10%", color: "black" }}
                  className="is-size-6 has-text-weight-bold"
                ></div>
                <div
                  style={{ width: "65%", color: "black" }}
                  className="is-size-6 has-text-weight-bold"
                ></div>
                <div
                  style={{ width: "15%", color: "black" }}
                  className="is-size-6 has-text-weight-bold"
                ></div>
              </div>
              {configurationDetailed?.keys.map((key, index) => (
                <div
                  key={key.keyName}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "1px solid #ddd",
                    backgroundColor: "#f5f5f5",
                    margin: "0.5% 1% 0.5% 1%",
                    alignItems: "center",
                  }}
                >
                  {props.disabled || (
                    <img
                      src="XRed.svg"
                      alt="X"
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                        padding: "4px",
                        marginLeft: "1%",
                      }}
                      onClick={() =>
                        handleRemoveKeyx(Number(findKey(key.keyName)?.id))
                      }
                    />
                  )}
                  <div
                    style={{ width: "10%", color: "black", padding: "10px" }}
                  >
                    {key.keyName}
                  </div>
                  <div
                    style={{
                      width: "10%",
                      color: "black",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      {props.disabled || (
                        <div className="inputValueChangerForm">
                          <div
                            onClick={() =>
                              handleInputChangeAmont(
                                index,
                                Number(findKey(key.keyName)?.amount),
                                "amount",
                                -1
                              )
                            }
                          >
                            <img
                              src="/img/configurator/minus.svg"
                              alt="minus"
                              style={{ width: "12px" }}
                            />
                          </div>
                        </div>
                      )}
                      <input
                        type="number"
                        readOnly={true}
                        disabled={props.disabled}
                        value={findKey(key.keyName)?.amount}
                        style={{
                          width: "35%",
                          border: "none",
                          backgroundColor: "#F3F3F3",
                          borderBottom: "#B1B1C5 solid 1px",
                          textAlign: "center",
                        }}
                      />
                      {props.disabled || (
                        <div className="inputValueChangerForm">
                          <div
                            onClick={() =>
                              handleInputChangeAmont(
                                index,
                                Number(findKey(key.keyName)?.amount),
                                "amount",
                                1
                              )
                            }
                          >
                            <img
                              src="/img/configurator/plus.svg"
                              alt="minus"
                              style={{ width: "12px" }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    style={{ width: "40%", color: "black", padding: "10px" }}
                  ></div>
                  <div
                    style={{
                      width: "20%",
                      fontSize: "14px",
                      color: "black",
                      padding: "10px",
                      textAlign: "right",
                    }}
                  >
                    {String(
                      key.price === null ? "0,0" : key.price.toFixed(2)
                    ).replace(".", ",")}{" "}
                    € ohne MwSt.
                  </div>
                  <div
                    style={{
                      width: "20%",
                      fontSize: "14px",
                      color: "black",
                      padding: "10px",
                      textAlign: "right",
                    }}
                  >
                    {String(
                      key.priceBrutto === null
                        ? "0,0"
                        : key.priceBrutto.toFixed(2)
                    ).replace(".", ",")}{" "}
                    € inkl. MwSt. 19%
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              padding: "20px 20px 0 20px",
            }}
          >
            <div
              style={{ width: "45%", color: "black" }}
              className="is-size-6 has-text-weight-bold"
            >
              <textarea
                style={{
                  width: "100%",
                  height: "100%",
                  resize: "none",
                  border: "#B1B1C5 solid 1px",
                  padding: "1%",
                }}
                name="comment"
                id="comment"
                placeholder="Kommentar hinzufügen"
                value={comment}
                onChange={(e) => {
                  setComment(e.target.value);
                  storeData("comment", e.target.value);
                }}
                disabled={props.disabled}
              />
            </div>
            <div
              style={{
                width: "30%",
                color: "black",
                textAlign: "center",
                display: "flex",
                justifyContent: "space-around",
              }}
              className="is-size-6 has-text-weight-bold"
            >
              <span style={{ fontWeight: "normal" }}>Zusammenfassung:</span>
            </div>
            <div
              style={{
                width: "25%",
                color: "black",
                borderBottom: "black solid 1px",
                display: "flex",
              }}
              className="is-size-6 has-text-weight-bold"
            >
              <div
                style={{ width: "20%", textAlign: "left", marginTop: "auto" }}
              >
                +
              </div>

              <div style={{ width: "80%", textAlign: "right" }}>
                {String(
                  configurationDetailed?.sumNetto === null
                    ? "0,0"
                    : configurationDetailed?.sumNetto.toFixed(2)
                ).replace(".", ",")}{" "}
                € ohne MwSt.
                <br />
                {String(
                  configurationDetailed?.sumBrutto === null
                    ? "0,0"
                    : configurationDetailed?.tax.toFixed(2)
                ).replace(".", ",")}{" "}
                € MwSt. 19%
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              padding: " 0 20px 20px 20px",
            }}
          >
            <div
              style={{ width: "10%", color: "black" }}
              className="is-size-6 has-text-weight-bold"
            ></div>
            <div
              style={{ width: "15%", color: "black" }}
              className="is-size-6 has-text-weight-bold"
            ></div>
            <div
              style={{
                width: "60%",
                color: "black",
                textAlign: "center",
                display: "flex",
                justifyContent: "space-around",
              }}
              className="is-size-6 has-text-weight-bold"
            ></div>
            <div
              style={{
                width: "25%",
                color: "black",
                display: "flex",
              }}
              className="is-size-6 has-text-weight-bold"
            >
              <div
                style={{ width: "20%", textAlign: "left", marginTop: "10%" }}
              ></div>

              <div style={{ width: "80%", textAlign: "right" }}>
                {String(
                  configurationDetailed?.sumBrutto === null
                    ? "0,0"
                    : configurationDetailed?.sumBrutto.toFixed(2)
                ).replace(".", ",")}{" "}
                € inkl. MwSt.
              </div>
            </div>
          </div>

          <div
            className="LockConfiguratorButons"
            style={{
              display: props.disabled ? "none" : "flex",
            }}
          >
            <Link to={"/2"}>
              <div className="LockConfiguratorButon">
                <div className="text-wrapper1">Zurück</div>
              </div>
            </Link>
            <Link
              to="/4"
              onClick={(e) => {
                saveToDB();
                const navbarElement = document.getElementById("navbar");
                navbarElement?.scrollIntoView({ behavior: "smooth" });
              }}
            >
              <div className="LockConfiguratorButon">
                <div className="text-wrapper">Zur Bestellung</div>
                <img className="vector" alt="Vector" src="Vector.svg" />
              </div>
            </Link>
            {/* <Link to="/3" onClick={(e) => downloadExcel()}>
              <div
                className="LockConfiguratorButon"
                style={{ margin: "3% 0 3% 0" }}
              >
                <div className="text-wrapper">Drucken</div>
                <img className="vector" alt="Vector" src="Drucken.svg" />
              </div>
            </Link> */}
          </div>
        </div>
      ) : (
        <LoadingPage />
      )}
    </div>
  );
};

export default SpecialOptions;
