import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sort from "./Sort";
import {
  IconOne,
  IconTwo,
  IconThree,
  IconFour,
  IconFive,
  IconSix,
  IconSeven,
  IconEigth,
} from "./icon1";
import { apiServer } from "../cinfig";
import LoadingPage from "../LoadingPage";


interface SystemInfo {
  supllier: string;
  system1: string;
  slogan: string;
  specialfeature: string;
  patentprotection: number;
}

interface Configuration {
  supllier: string;
  system1: string;
  price: number;
}
interface ConfigurationDetail {
  filteredResult: Configuration[];
  maxValue: string;
  minValue: string;
}

const ChooseLockSystem = () => {
const [pageIsLoaded, setPageIsLoaded] = useState<boolean>(false);

  const [configuration, setConfiguration] = useState<ConfigurationDetail>({
    filteredResult: [],
    maxValue: "",
    minValue: "",
  });
  const [configurationSort, setConfigurationSort] =
    useState<ConfigurationDetail>({
      filteredResult: [],
      maxValue: "",
      minValue: "",
    });
  const [sort, setSort] = useState("");

  useEffect(() => {

    switch (sort) {
      case "priceUp":
        const valueUp = configuration.filteredResult
          .slice()
          .sort((a, b) => Number(a.price) - Number(b.price));
        setConfiguration({
          filteredResult: valueUp,
          maxValue: configuration.maxValue,
          minValue: configuration.minValue,
        });
        break;

      case "priceDown":
        const valueDown = configuration.filteredResult
          .slice()
          .sort((a, b) => Number(b.price) - Number(a.price));
        setConfiguration({
          filteredResult: valueDown,
          maxValue: configuration.maxValue,
          minValue: configuration.minValue,
        });

        break;

      case "supllierAZ":
        const valueAZModel = configuration.filteredResult.slice().sort((a, b) => {
          const nameA = a.system1.toUpperCase();
          const nameB = b.system1.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        const valueAZ = valueAZModel.slice().sort((a, b) => {
          const nameA = a.supllier.toUpperCase();
          const nameB = b.supllier.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setConfiguration({
          filteredResult: valueAZ,
          maxValue: configuration.maxValue,
          minValue: configuration.minValue,
        });
        break;

      case "supllierZA":
        const valueZAModel = configuration.filteredResult.slice().sort((a, b) => {
          const nameA = a.system1.toUpperCase();
          const nameB = b.system1.toUpperCase();
          if (nameA < nameB) {
            return 1;
          }
          if (nameA > nameB) {
            return -1;
          }
          return 0;
        });
        const valueZA = valueZAModel.slice().sort((a, b) => {
          const nameA = a.supllier.toUpperCase();
          const nameB = b.supllier.toUpperCase();
          if (nameA < nameB) {
            return 1;
          }
          if (nameA > nameB) {
            return -1;
          }
          return 0;
        });
        setConfiguration({
          filteredResult: valueZA,
          maxValue: configuration.maxValue,
          minValue: configuration.minValue,
        });
        break;

      default:
        break;
    }
  }, [sort]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiServer}/api/v1/configurator/systemsInfo`
          
        );
        setTableData(response.data);
      } catch (error) {
        console.error("Błąd podczas pobierania danych:", error);
      }
      try {
        const response = await axios.post(
          `${apiServer}/api/v1/configurator/configuration?sortby=${sort}`,
          [{ doors, keys }]
        );
        setConfiguration(response.data);
        setConfigurationSort(response.data);
      } catch (error) {
        console.error("Błąd podczas pobierania danych:", error);
      }finally{
        setPageIsLoaded(true)
      }
    };
    fetchData();

  }, []);
  const toggleRow = (index: number) => {
    const updatedRows = [...expandedRows];
    const rowIndex = updatedRows.indexOf(index);

    if (rowIndex === -1) {
      updatedRows.push(index);
    } else {
      updatedRows.splice(rowIndex, 1);
    }

    setExpandedRows(updatedRows);
  };
  const hasPrice = (product: SystemInfo): boolean => {
    const price = findPrice(product.supllier, product.system1);
    return price !== undefined;
  };
  const getDataFromSession = (key: string, defaultValue: any) => {
    const storedData = localStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : defaultValue;
  };
  const storeDataInSession = (key: string, data: any) => {
    localStorage.setItem(key, JSON.stringify(data));
  };
  const doors = getDataFromSession("doors", []);
  const keys = getDataFromSession("keys", []);
  const permission = getDataFromSession("permission", []);
  const [tableData, setTableData] = useState<SystemInfo[]>([]);

  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const findPrice = (supllier: string, system1: string): number | undefined => {
    const config = configurationSort?.filteredResult.find(
      (config) =>
        config.supllier === supllier &&
        config.system1 === system1.replace(".", " ")
    );
    return config ? config.price : undefined;
  };
 
  const findSystem = (system1: string): SystemInfo | undefined => {
    let trimmedSystem1 = system1.trim();
    const firstThreeLetters = trimmedSystem1.slice(0, 3).toUpperCase();
  
    // Sprawdzenie, czy "UDM+" występuje na początku i zamiana na "UDMplus"
    if (firstThreeLetters === "UDM" && trimmedSystem1.charAt(3) === '+') {
      trimmedSystem1 = "UDMplus" + trimmedSystem1.slice(4);
    }
  
    return tableData.find((config) => {
      let trimmedConfigSystem1 = config.system1.trim();
  
      // Podobna zamiana dla elementów w tableData
      if (trimmedConfigSystem1.slice(0, 3).toUpperCase() === "UDM" && trimmedConfigSystem1.charAt(3) === '+') {
        trimmedConfigSystem1 = "UDMplus" + trimmedConfigSystem1.slice(4);
      }
  
      if (firstThreeLetters === "BRA" || firstThreeLetters === "SK6" || firstThreeLetters === "UDM") {
        // Jeśli pierwsze trzy litery to "BRA", "SK6" lub "UDM", zamień kropki na spacje i porównaj cały ciąg
        const formattedSystem1 = trimmedSystem1.replace(/\./g, " ");
        const formattedConfigSystem1 = trimmedConfigSystem1.replace(/\./g, " ");
        return formattedConfigSystem1 === formattedSystem1;
      } else {
        // W przeciwnym razie porównaj tylko pierwsze trzy litery
        return trimmedConfigSystem1.slice(0, 3).toUpperCase() === firstThreeLetters;
      }
    });
  };
  

  const storeData = (key: string, data: any) => {
    localStorage.setItem(key, JSON.stringify(data));
  };
  const handleOnSelectSystem = (supllier: String, system1: String) => {
    storeData("supllier", supllier);
    storeData("system1", system1);
  };
  const handleSortChange = (currentSort: string, sort: string) => {
    let newSortChoice: string = "priceUp";
    if (currentSort.startsWith("price") && sort.startsWith("price")) {
      switch (currentSort) {
        case "priceUp":
          newSortChoice = "priceDown";
          break;
        case "priceDown":
          newSortChoice = "priceUp";
          break;
      }
    } else if (currentSort.startsWith("price") && sort.startsWith("supllier")) {
      switch (currentSort) {
        case "supllierAZ":
          newSortChoice = "supllierZA";
          break;
        case "supllierZA":
          newSortChoice = "supllierAZ";
          break;
        default:
          newSortChoice = "supllierAZ";
      }
    }
    if (currentSort.startsWith("supllier") && sort.startsWith("supllier")) {
      switch (currentSort) {
        case "supllierAZ":
          newSortChoice = "supllierZA";
          break;
        case "supllierZA":
          newSortChoice = "supllierAZ";
          break;
      }
    } else if (
      currentSort.startsWith("supllier") &&
      sort.startsWith("supllier")
    ) {
      switch (currentSort) {
        case "priceUp":
          newSortChoice = "priceDown";
          break;
        case "priceDown":
          newSortChoice = "priceUp";
          break;
        default:
          newSortChoice = "priceUp";
      }
    }

    setSort(newSortChoice);
  };

  return (
    <div
      className="choose-lock-system"
      style={{ width: "75%", margin: "65px auto", overflow: "hidden" }}
    >
      {/* Tytuł */}
      <div style={{ textAlign: "left", margin: "20px 0", padding: "1rem" }}>
        <p
          style={{
            fontSize: "1.2rem",
            fontWeight: 800,
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <img
            src={"./img/2.png"}
            alt="Tytuł"
            style={{ width: "1.2rem", marginRight: "0.3rem" }}
          />
          Wählen Sie Ihre Schließanlage
        </p>
        <Sort
          configuration={configuration}
          setConfigurationSort={setConfigurationSort}
          onConfigurationChange={setConfiguration}
          sort={sort}
          price={{
            minPrice: configuration.minValue,
            maxPrice: configuration.maxValue,
          }}
        />
        <span style={{ fontSize: "14px" }}>
          Ergebnisse {configurationSort.filteredResult.length}
        </span>
      </div>

      <br />

      {/* Tabela */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid #ddd",
          borderBottom: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#005CA9",
            color: "#FFFFFF",
            height: "38px",
            alignContent: "center",
            flexFlow: "wrap",
            textAlign: "center",
          }}
        >
          <div
            style={{ flexBasis: "15%", flexGrow: 0, cursor: "pointer" }}
            onClick={(e) => handleSortChange(sort, "supllierAZ")}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingRight: "5%",
              }}
            >
              <p className="is-size-6 has-text-weight-bold">Produkt</p>
              <img
                className="vector"
                style={{
                  marginLeft: "5px",
                  width: "15px",
                  rotate:
                    sort === "supllierAZ"
                      ? "270deg"
                      : sort === "supllierZA"
                      ? "90deg"
                      : "",
                  display:
                    sort === "supllierAZ"
                      ? "block"
                      : sort === "supllierZA"
                      ? "block"
                      : "none",
                }}
                alt="Vector"
                src="Vector.svg"
              />
            </div>
          </div>
          <div style={{ flexBasis: "49%", flexGrow: 0 }}>
            <p className="is-size-6 has-text-weight-bold">Eigenschaften</p>
          </div>
          <div
            style={{ flexBasis: "18.5%", flexGrow: 0, cursor: "pointer" }}
            onClick={(e) => handleSortChange(sort, "priceUp")}
          >
            <div style={{ display: "flex" }}>
              <p className="is-size-6 has-text-weight-bold">
                Preis inkl. MwSt. 19%
              </p>
              <img
                className="vector"
                style={{
                  marginLeft: "5px",
                  width: "15px",
                  rotate:
                    sort === "priceUp"
                      ? "270deg"
                      : sort === "priceDown"
                      ? "90deg"
                      : "",
                  display:
                    sort === "priceUp"
                      ? "block"
                      : sort === "priceDown"
                      ? "block"
                      : "none",
                }}
                alt="Vector"
                src="Vector.svg"
              />
            </div>
          </div>
          <div style={{ flexBasis: "5%", flexGrow: 0 }}>
            <p className="is-size-6 has-text-weight-bold"></p>
          </div>
          <div style={{ flexBasis: "19.5%", flexGrow: 0 }}>
            <p className="is-size-6 has-text-weight-bold"></p>
          </div>
        </div>

        {pageIsLoaded ? "" : <LoadingPage />}
        {configurationSort.filteredResult.map(
          (product, index) =>
            product.price && (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  border: "1px solid #B1B1C5",
                  backgroundColor: "#F3F3F3",
                  margin: "0.5% 1% 0.5% 1%",
                  justifyContent: "center",
                  padding: "5px 0px",
                }}
              >
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    border: "1px solid #B1B1C5",
                    backgroundColor: "#F3F3F3",
                    margin: "0.5% 1% 0.5% 1%",
                    justifyContent: "center",
                    padding: "5px 0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        flexBasis: "25%",
                        flexGrow: 0,
                        display: "flex",
                        flexFlow: "wrap",
                        alignItems: "center",
                      }}
                    >
                      <img
                        style={{
                          width: "70px",
                          height: "40px",
                          float: "left",
                          margin: "0px 10px",
                        }}
                        src={`/img/${product.supllier.trim()}.jpeg`}
                        alt="logo"
                      ></img>
                      <div>
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "black",
                            justifyContent: "center",
                          }}
                        >
                          {product.supllier}
                        </span>
                        <br />
                        <span style={{ fontSize: "14px", color: "black" }}>
                          {product.system1}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        flexBasis: "28%",
                        flexGrow: 0,
                        fontSize: "14px",
                        alignItems: "center",
                        display: "flex",
                        flexFlow: "wrap",
                        justifyContent: "center",
                        
                      }}
                    >
                      <IconOne product={product} />
                      <IconTwo product={product} />
                      <IconThree product={product} />
                      <IconFour product={product} />
                      <IconFive product={product} />
                      <IconSix product={product} />
                      <IconSeven product={product} />
                      <IconEigth product={product} />
                    </div>
                    <div
                      style={{
                        flexBasis: "10%",
                        flexGrow: 0,
                        fontSize: "14px",
                        color: "black",
                        fontWeight: "bold",
                        alignContent: "center",
                        display: "flex",
                        flexFlow: "wrap",
                        justifyContent: "center",
                        paddingLeft: "3%",
                        marginLeft: "8%",
                      }}
                    >
                      {findPrice(product.supllier, product.system1)
                        ? `${String(
                            Number(
                              findPrice(product.supllier, product.system1)
                            ).toFixed(2)
                          ).replace(".", ",")} €`
                        : "Brak"}
                    </div>
                    <div
                      style={{
                        flexBasis: "5%",
                        flexGrow: 0,
                        textAlign: "center",
                        alignContent: "center",
                        display: "flex",
                        flexFlow: "wrap",
                      }}
                      title="Weitere Informationen"
                    >
                      {expandedRows.includes(index) ? (
                        <img
                          style={{
                            width: "25px",
                            height: "25px",
                            rotate: "180deg",
                            cursor: "pointer",
                          }}
                          onClick={() => toggleRow(index)}
                          src="./img/DeltaButton.svg"
                          alt="arrow"
                        ></img>
                      ) : (
                        <img
                          style={{
                            width: "25px",
                            height: "25px",
                            cursor: "pointer",
                          }}
                          onClick={() => toggleRow(index)}
                          src="./img/DeltaButton.svg"
                          alt="arrow"
                        ></img>
                      )}
                    </div>
                    <div
                      style={{
                        flexBasis: "25%",
                        flexGrow: 0,
                        textAlign: "center",
                        display: "flex",
                        flexFlow: "wrap",
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Link to={"/3"}>
                        <button
                          onClick={(e) => {
                            handleOnSelectSystem(
                              product.supllier,
                              product.system1
                            );
                          }}
                          style={{
                            backgroundColor: "#005CA9",
                            color: "white",
                            border: "none",
                            padding: "5px 10px",
                            cursor: "pointer",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              color: "white",
                              border: "none",
                              padding: "5px 10px",
                              alignItems: "center",
                            }}
                          >
                            Auswählen und fortfahren
                          </div>
                          <img
                            className="vector"
                            alt="Vector"
                            src="Vector.svg"
                          />
                        </button>
                      </Link>
                    </div>
                  </div>
                  {expandedRows.includes(index) && (
  <div>
    <div style={{ margin: "15px 30px 5px" }}>
      <strong>{findSystem(product.system1)?.slogan}</strong>
    </div>
    <div style={{ margin: "5px 30px" }}>
      <strong>Produktvorteil:</strong>{" "}
      {findSystem(product.system1)?.specialfeature}
    </div>
    <div style={{ margin: "5px 30px" }}>
      <strong>Patentschutz mind. bis:</strong>{" "}
      {findSystem(product.system1)?.patentprotection}
    </div>
  </div>
)}
                  
                </div>
              </div>
            )
        )}
      </div>
      <div
        className="LockConfiguratorButons"
        style={{ width: "100%", marginTop: "3%", overflow: "hidden" }}
      >
        <Link to={"/1"}>
          <div className="LockConfiguratorButon">
            <div className="text-wrapper1">Zurück</div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ChooseLockSystem;
